/** @jsx jsx **/
import { BaseStyles, Box, Container, Grid, jsx } from 'theme-ui'
import { PageProps, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import theme from '~/gatsby-plugin-theme-ui'
import Img from 'gatsby-image'
import Layout from '~/components/layout'
import SanitizeHtml from '~/components/sanitize-html'
import { ContentFieldsType, GatsbyImageType } from '~/@types/models'

interface Props extends PageProps {
  data: {
    page: {
      id: string
      frontmatter: {
        title: string
        customCss?: {
          columns?: string
          gap?: string
        }
        imageLeft?: GatsbyImageType
        imageBottom?: GatsbyImageType
      }
      html: string
      fields: ContentFieldsType
    }
  }
}

export const widePageQuery = graphql`
  query widePageQuery($slug: String) {
    page: markdownRemark(fields: { sourceName: { eq: "widePages" }, slug: { eq: $slug } }) {
      frontmatter {
        title
        customCss {
          columns
          gap
        }
        imageLeft {
          ...heroImage
        }
        imageBottom {
          ...heroImage
        }
      }
      html
      ...markdownFields
    }
  }
`

const PageWide = ({ data: { page } }: Props) => {
  const { title, customCss, imageLeft, imageBottom } = page.frontmatter
  const { html: body } = page

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints[2]})`,
  })

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Container
        variant="containerPrimary"
        sx={{
          maxWidth: '1380px',
          mt: '50px',
          mb: '30px',
        }}
      >
        <Grid columns={!isMobile ? [customCss?.columns] : '1fr'} gap={customCss?.gap}>
          {!!imageLeft && (
            <Box>
              <Img
                fluid={imageLeft?.childImageSharp.fluid}
                sx={{
                  maxWidth: '100%',
                }}
              />
            </Box>
          )}
          <Box>
            <BaseStyles
              sx={{
                h1: {
                  textTransform: 'uppercase',
                  fontSize: '32px',
                  mb: '30px',
                },
                h3: {
                  mb: '40px',
                },
                p: {
                  fontSize: '16px',
                  mb: '23px',
                  fontWeight: 'normal',
                },
                a: {
                  color: '#666',
                },
                ol: {
                  fontWeight: 'normal',
                },
                ul: {
                  variant: 'variants.list.secondary',
                },
                hr: {
                  mb: '30px',
                },
              }}
            >
              <h1>{title}</h1>
              <SanitizeHtml content={body} />
            </BaseStyles>
            {!!imageBottom && (
              <Img
                fluid={imageBottom?.childImageSharp.fluid}
                sx={{
                  mt: 50,
                  maxWidth: '100%',
                }}
              />
            )}
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default PageWide
